import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import checkCircle from "./assets/check-circle-1.png";
import checkCircleWhite from "./assets/check-circle-1-white.png";
import checkCircleGreen from "./assets/check-circle-1-green.png";
import checkCircleGold from "./assets/check-circle-1-gold.png";

import "./GiftCard.css";
import axios from "axios";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  /*  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    border: "1px solid #979797",
  }, */
  /*  card: {
    width: "100%", // Adjust the width based on your design
    border: "none !important",
    outlineStyle: "none !important",
    boxShadow: "none",
    padding: "40px",
    borderRadius: "30px",
    transition: "transform 0.3s, box-shadow 0.3s",

    "&:hover": {
      transform: "translateY(-50px)",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    },
  }, */
}));

const PricingCard = ({
  title,
  price,
  features,
  index,
  id,
  middleCardPosition,
}) => {
  const classes = useStyles();

  return (
    <Card
      style={{
        height: "100%",
      }}
      /* onMouseEnter={index !== 1 && handleMouseEnterOther}
      onMouseLeave={index !== 1 && handleMouseLeaveOther} */
      //for color change
      //  ${index == 0 && "first-second-card"}

      //${index == 2 && "first-second-card"}

      //for middle card color change
      //  index == 1 && middleCardPosition != 0
      className={`card-translate-animation
      
    

       ${index == 1 ? "premium-gray-rectangle shadow-rectangle" : ""} ${
        index === 4 && "premium-green-rectangle shadow-rectangle"
      } ${index == 7 && "premium-gold-rectangle shadow-rectangle"}`}
    >
      <CardContent className={classes.cardContent}>
        <div style={{ paddingTop: "50px" }}>
          <Typography
            className={`gift-card-price ${
              (index == 1 || index == 4 || index == 7) && "gift-card-3-price"
            }`}
          >
            ৳{price}
          </Typography>

          <Typography
            className={`${
              (index == 1 || index == 4 || index == 7) && "gift-card-3-name"
            } gift-card-name`}
            // variant="h5"
            gutterBottom
          >
            {title}
          </Typography>
          {index <= 2 && (
            <Typography
              className={`student-seeking ${index == 1 && "student-2-seeking"}`}
            >
              For Student seeking online counseling.
            </Typography>
          )}
          {index <= 5 && index >= 3 && (
            <Typography
              className={`student-seeking ${index == 4 && "student-2-seeking"}`}
            >
              For Individuals seeking online counseling.
            </Typography>
          )}
          {index <= 8 && index > 5 && (
            <Typography
              className={`student-seeking ${index == 7 && "student-2-seeking"}`}
            >
              For Individuals seeking premium <br /> online counseling.
            </Typography>
          )}
          {features.map((feature, indexinner) => (
            <Typography
              key={indexinner}
              // variant="body2"
              color="textSecondary"
              style={{
                textAlign: "left",
              }}

              //   component="li"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  textAlign: "left",
                  height:
                    (index == 2 ||
                      index == 5 ||
                      index === 8 ||
                      index === 1 ||
                      index === 4 ||
                      index === 7) &&
                    "55px",
                  // height: '55px'
                }}
              >
                {(index == 1 || index == 4 || index == 7) && (
                  <img src={checkCircleWhite} alt="" srcset="" />
                )}
                {(index == 0 || index == 2) && (
                  <img src={checkCircle} alt="" srcset="" />
                )}
                {(index == 3 || index == 5) && (
                  <img src={checkCircleGreen} alt="" srcset="" />
                )}
                {(index == 6 || index == 8) && (
                  <img src={checkCircleGold} alt="" srcset="" />
                )}

                <p
                  className={`${
                    (index == 1 || index == 4 || index == 7) &&
                    "gift-card-3-features"
                  } gift-card-features`}
                >
                  {feature}
                </p>
              </div>
            </Typography>
          ))}
          <Typography>
            <Link style={{ textDecoration: "none" }} to={`/gift-card/${id}`}>
              <div className="gift-card-btn-custom">
                <div
                  className={`gift-card-button-custom ${
                    index == 1 && "gift-card-friends-button"
                  } ${index == 4 && "gift-card-green-button"} ${
                    index == 7 && "gift-card-gold-button"
                  }  ${index == 0 && "gift-card-first-button"} ${
                    (index == 6 || index == 3) && "gift-card-last-button"
                  }`}
                >
                  <p>Choose Plan</p>
                </div>
              </div>
            </Link>
          </Typography>
        </div>
        {/* bondhu-3  */}
        {index == 1 && (
          <Typography
            className="gift-card-popular"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Popular
          </Typography>
        )}
        {/* bondhu-3 end */}

        {/* happiness-3 */}
        {index == 4 && (
          <Typography
            className="gift-card-popular"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Popular
          </Typography>
        )}
        {/* happiness-3 end */}
        {/* premium -3 start */}
        {index == 7 && (
          <Typography
            className="gift-card-popular"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Popular
          </Typography>
        )}
        {/* premium-3 end */}

        {/* bondhu-5 */}
        {index == 2 && (
          <Typography
            className="gift-card-value"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Value
          </Typography>
        )}
        {/* bondhu-5 */}

        {/* happiness-5 */}
        {index == 5 && (
          <Typography
            className="gift-card-green-value"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Value
          </Typography>
        )}
        {/* happiness-5 end */}

        {/* premium-5 start */}
        {index == 8 && (
          <Typography
            className="gift-card-gold-value"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            Most Value
          </Typography>
        )}
        {/* premium-5 ends */}
      </CardContent>
    </Card>
  );
};

const Pricing = () => {
  // const classes = useStyles();
  const [middleCardPosition, setMidddleCardPosition] = useState(-50);
  const [middleCardPositionSecond, setMidddleCardPositionSecond] =
    useState(-50);
  const [middleCardPositionThird, setMidddleCardPositionThird] = useState(-50);
  const [mouseEnterTrack, setMouseEnterTrack] = useState(false);
  const [mouseEnterTrackSecond, setMouseEnterTrackSecond] = useState(false);
  const [mouseEnterTrackThird, setMouseEnterTrackThird] = useState(false);

  const handleMouseEnter = (index) => {
    setMouseEnterTrack(true);
    console.log(index);
  };

  const handleMouseLeave = (index) => {
    setMouseEnterTrack(false);
  };

  const handleMouseEnterSecond = () => {
    setMouseEnterTrackSecond(true);
  };
  const handleMouseLeaveSecond = () => {
    setMouseEnterTrackSecond(false);
  };
  const handleMouseEnterThird = () => {
    setMouseEnterTrackThird(true);
  };
  const handleMouseLeaveThird = () => {
    setMouseEnterTrackThird(false);
  };
  useEffect(() => {
    if (mouseEnterTrack) {
      setMidddleCardPosition(0);
    }
    if (!mouseEnterTrack) {
      setMidddleCardPosition(-50);
    }
  }, [mouseEnterTrack]);
  useEffect(() => {
    if (mouseEnterTrackSecond) {
      setMidddleCardPositionSecond(0);
    }
    if (!mouseEnterTrackSecond) {
      setMidddleCardPositionSecond(-50);
    }
  }, [mouseEnterTrackSecond]);
  useEffect(() => {
    if (mouseEnterTrackThird) {
      setMidddleCardPositionThird(0);
    }
    if (!mouseEnterTrackThird) {
      setMidddleCardPositionThird(-50);
    }
  }, [mouseEnterTrackThird]);
  const [giftCards, setGiftCards] = useState();
  useEffect(() => {
    const fetchGiftCards = async () => {
      await axios
        .get("https://api.thepsycure.com/gift-cards")
        .then((data) => setGiftCards(data.data))
        .catch((err) => console.log(err));
    };
    fetchGiftCards();
  }, []);
  return (
    <div>
      {/* bondhu starts */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "7%",
        }}
      >
        <div>
          <p className="student-packages-header">Student Packages</p>
          <p className="student-packages-header-details">
            Validity: Effective from purchase
            <br /> date for 3 months.
          </p>
        </div>
      </div>
      <Grid
        justifyContent="center"
        spacing={1}
        style={{
          // width: "50%",
          width: "991px",

          margin: "0 auto",
          border: "1px solid #979797",
          borderRadius: "30px",
          padding: "10px",
        }}
        xl={6}
        lg={9}
        xs={11}
        md={11}
        sm={11}
        container
      >
        {giftCards?.slice(0, 3)?.map((giftCard, index) => (
          <Grid
            key={index}
            sx={{
              /* height: "522.15px",
              width: "317.64px", */
              height: "522.15px",
              width: "317.64px",
              transform: `translateY(${
                index === 1 && middleCardPosition + "px"
              })`,
              transition: "all 0.3s",
              position: "relative",
            }}
            item
            xl={4}
            lg={4}
            md={4}
            sm={11}
            xs={12}
            className={`${index === 1 && "middle-card-translate"} ${
              (index == 0 || index == 2) && "hover-cards"
            }`}
            onMouseEnter={index !== 1 && handleMouseEnter}
            onMouseLeave={index !== 1 && handleMouseLeave}
          >
            <PricingCard
              price={giftCard.packagePrice}
              title={giftCard.packageName}
              features={giftCard.features}
              index={index}
              id={giftCard._id}
              middleCardPosition={middleCardPosition}
            ></PricingCard>
          </Grid>
        ))}
      </Grid>
      {/* bondhu ends */}

      {/* regular starts  */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "7%",
        }}
      >
        <div>
          <p className="student-packages-header">Regular Packages</p>
          <p className="student-packages-header-details">
            Validity: Effective from purchase
            <br /> date for 3 months.
          </p>
        </div>
      </div>
      <Grid
        // columns={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
        justifyContent="center"
        spacing={1}
        style={{
          width: "991px",
          margin: "0 auto",
          border: "1px solid #979797",
          borderRadius: "30px",
        }}
        xl={6}
        lg={9}
        xs={11}
        md={11}
        sm={11}
        container
      >
        {giftCards?.slice(3, 6)?.map((giftCard, index) => (
          <Grid
            key={index}
            item
            sx={{
              height: "522.15px",
              width: "317.64px",
              transform: `translateY(${
                index == 1 && middleCardPositionSecond + "px"
              })`,

              transition: "all 0.3s",
              position: "relative",
            }}
            xl={4}
            lg={4}
            md={4}
            sm={11}
            xs={12}
            className={`${index == 1 && "middle-card-translate"} ${
              (index == 0 || index == 2) && "hover-cards"
            }`}
            onMouseEnter={index !== 1 && handleMouseEnterSecond}
            onMouseLeave={index !== 1 && handleMouseLeaveSecond}
          >
            <PricingCard
              price={giftCard.packagePrice}
              title={giftCard.packageName}
              features={giftCard.features}
              index={index + 3}
              id={giftCard._id}
            ></PricingCard>
          </Grid>
        ))}
      </Grid>
      {/* regular ends */}

      {/* premium starts */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "7%",
        }}
      >
        <div>
          <p className="student-packages-header">Premium Packages</p>
          <p className="student-packages-header-details">
            Validity: Effective from purchase
            <br /> date for 3 months.
          </p>
        </div>
      </div>
      <Grid
        // columns={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
        spacing={1}
        justifyContent="center"
        style={{
          width: "991px",
          margin: "0 auto",
          border: "1px solid #979797",
          borderRadius: "30px",
        }}
        xl={6}
        lg={9}
        xs={11}
        md={11}
        sm={11}
        container
      >
        {giftCards?.slice(6, 10)?.map((giftCard, index) => (
          <Grid
            key={index}
            sx={{
              height: "522.15px",
              width: "317.64px",
              transform: `translateY(${
                index === 1 && middleCardPositionThird + "px"
              })`,
              transition: "all 0.3s",
              position: "relative",
            }}
            item
            xl={4}
            lg={4}
            md={4}
            sm={11}
            xs={12}
            className={`${index === 1 && "middle-card-translate"} ${
              (index == 0 || index == 2) && "hover-cards"
            }`}
            onMouseEnter={index !== 1 && handleMouseEnterThird}
            onMouseLeave={index !== 1 && handleMouseLeaveThird}
          >
            <PricingCard
              price={giftCard.packagePrice}
              title={giftCard.packageName}
              features={giftCard.features}
              index={index + 6}
              id={giftCard._id}
            ></PricingCard>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Pricing;
